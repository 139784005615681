<template>
  <div :class="$vuetify.breakpoint.xsOnly ? '' : 'page-wrap'">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <v-avatar
            size="60"
            class="mb-4"
            rounded
          >
            <img
              src="/assets/logo/ktgLogo.png"
              alt=""
            >
          </v-avatar>
          <h5>
            Sign up to play
          </h5>
          <v-text-field
            v-model="firstName"
            label="First Name"
          />
          <v-text-field
            v-model="lastName"
            label="Last Name"
          />
          <v-text-field
            v-model="email"
            label="Email"
          />

          <v-text-field
            v-model="displayName"
            label="Display Name"
            hint="Leave blank to use your name"
            persistent-hint>
            <template v-slot:append-outer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                </template>
                <span>Display name will be visible to other players</span>
              </v-tooltip>
            </template>
          </v-text-field>

          <v-select
            :items="counties"
            v-model="county"
            label="County"
          />
          <v-combobox
            :disabled="!county"
            :items="getClubs"
            v-model="updatedClub"
            item-value="id"
            :search-input.sync="search"
            @update:search-input
            item-text="name"
            label="Club"
          />

          <v-text-field
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            name="input-10-2"
            label="Password"
            @click:append="show = !show"
          />
          <v-text-field
            v-model="passwordConfirmation"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            name="input-10-2"
            label="Confirm Password"
            @click:append="show = !show"
          />
          <v-checkbox
            v-model="acceptedTermsAndConditions"
            label="I have read and agree to the terms of service."
          />
          <v-checkbox
            v-model="acceptedOfAge"
            class="mt-0"
            label="I am over 18 years of age."
          />
          <v-checkbox
            v-model="rememberMe"
            class="mt-0"
            label="Remember this computer"
          />
          <v-btn
            class="mb-4"
            block
            color="primary"
            dark
            :disabled="!enableSignUp"
            :loading="loading"
            @click="userSignUp"
          >
            Sign Up
          </v-btn>
          <div class="d-flex justify-around flex-wrap">
            <v-btn
              text
              small
              color="primary"
              to="/app/sessions/sign-in"
            >
              Sign in to existing account
            </v-btn>
          </div>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import redirectToCheckout from '@/auth/stripePayment.js';

  export default {
    name: 'SignUp',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'SignUp',
    },
    data () {
      return {
        show: false,
        firstName: null,
        lastName: null,
        updatedClub: null,
        email: null,
        displayName: null,
        password: null,
        search: '',
        county: null,
        passwordConfirmation: null,
        acceptedTermsAndConditions: false,
        acceptedOfAge: false,
        loading: false,
        rememberMe: true,
        counties: [
          "Carlow",
          "Cavan",
          "Clare",
          "Cork",
          "Donegal",
          "Dublin",
          "Galway",
          "Kerry",
          "Kildare",
          "Kilkenny",
          "Laois",
          "Leitrim",
          "Limerick",
          "Longford",
          "Louth",
          "Mayo",
          "Meath",
          "Monaghan",
          "Offaly",
          "Roscommon",
          "Sligo",
          "Tipperary",
          "Waterford",
          "Westmeath",
          "Wexford",
          "Wicklow"
      ],
        clubs: [],
      }
    },
    methods: {
      ...mapActions(['signup', 'changePassword', 'searchClubsByCounty']),
      async userSignUp(){
        this.loading = true;
        let clubId = this.updatedClub ? this.updatedClub.id : null;
        this.signup({"firstName": this.firstName, "lastName": this.lastName, "email": this.email, "clubId": clubId, 
          "password": this.password, "displayName": this.displayName, "rememberMe": this.rememberMe }).then((userId) => {
            if (userId) {
              redirectToCheckout();
            } else {
              next('/app/hurling/home');
            }
          })
          .catch((error) => {
              console.error('Error during route authentication:', error);
              next('/home');  // Redirect to an error page or handle as needed
          });
      },
      onSearch(search){
        this.searchClubsByCounty({"searchString":search, "county":this.county})
      },
      // async redirectToCheckout() {
      //   const response = await fetch('http://localhost:8080/create-checkout-session');
      //   const { sessionId } = await response.json();
      //   const stripe = Stripe('pk_live_51MWiWlJgqpX8vKED2inaCvTGm0vDhwfclkNBJHcKO2mP2oW6AhKrRLfTi1ilnab8bnsavPHIq04enx0iwL5dTBFF00W27as7hU');
      //   stripe.redirectToCheckout({ sessionId });
      // }
    },
    computed: {
      ...mapGetters(['getToken', 'getRequiresPasswordReset', 'getClubs']),
      enableSignUp(){
        if(this.acceptedTermsAndConditions && this.acceptedOfAge && this.firstName && this.lastName && this.email 
          && this.password && this.passwordConfirmation){
          return true;
        } else {
          return false;
        }
      }
    },
    watch: {
      search(val){
        if(val){
          this.onSearch(val);
        } else {
          this.clubs = [];
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
